import React, { useState } from "react";
import styled from "styled-components";

import Services1 from "../../assets/Services/air-ticketing.jpg";
import Services2 from "../../assets/Services/out-bound-tours.jpg";
import Services3 from "../../assets/Services/visa-assistant.jpg";
import Services4 from "../../assets/Services/travel-insurance.jpg";
import Services5 from "../../assets/Services/travel-consulation.jpg";
import Services6 from "../../assets/Services/visa-consulation.jpg";


import { NavLink } from "react-router-dom";

export default function servicelist() {
  const data = [
    {
      image: Services1,
      title: "Airline ticketing",
      subTitle: "Singapore, officialy thr Republic of Singapore, is a test gahhahj test ageyys",
      cost: "38,800",
      link: "/services1",
    },
  {
    image: Services2,
      title: "OUT BOUND TOURS",
      subTitle: "Malaysia's capital, is a major asia city and a",
      cost: "45,500",
      link: "/services2",
    },
    {
      image: Services3,
      title: "VISA ASSISTANT",
      subTitle: "Dubai, the capital of Sharja ",
      cost: "38,800",
      link: "/services3",
    },
    {
      image: Services4,
      title: "TRAVEL INSURANCE",
      subTitle: "Thailand is a Southeast Asia country. It's known for",
      cost: "54,200",
      link: "/services4",
    },

    {
      image: Services5,
      title: "TRAVEL CONSULTATION",
      subTitle: "Mumbai is a small South Pacific island northwest of",
      cost: "95,400",
      link: "/services5",
    },
      
    {
      image: Services6,
      title: "VISA CONSULTATION",
      subTitle: "Disneylan is an island country in the",
      cost: "24,100",
      link: "/services6",
    },
    
    
  ];


  // const [active, setActive] = useState(1);
  return (
    <Destinationsmain>
    <div className="main-container">
    
      <div className="title-center">
        <p> Three Travels </p>
        <h2>Our Services</h2>
      </div>
      

    <div className="tour-destination">
    {data.map((destination) => {
          return (
    
        <div className="destination-shadow-box">
          <NavLink className="destinations-link  "  to={destination.link}>
            <div className="img-hover-zoom">
        <img src={destination.image} alt="" /> 
        </div>

        <div className="destination-padding-box">
            <h3>{destination.title}</h3>
            {/* <p>{destination.subTitle}</p> */}
        </div>
        <div className="testbg hover-to-right"> Find Out more</div>
        </NavLink>
        </div>
    
          );
        })}
    </div>




    </div>

    </Destinationsmain>
  );
}

const Destinationsmain = styled.section`
    // margin: 0 0 4rem 0;
    padding: 4rem 0 4rem 0;
    background: #fff;

.title-center p{
  font-family: Poppins,sans-serif;
  font-size: 11px;
  letter-spacing: 4px;
  line-height: 18px;
  text-align:center;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
}

  .title-center h2 {
    margin-bottom: 30px;
    font-size: 36px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    font-weight: 400; 
    text-align:center;
}


.destinations-link {
  text-decoration: none;
}

.img-hover-zoom {
overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}



.tour-destination{
    overflow: hidden;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.destination-shadow-box{
  position: relative;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
  box-shadow: rgb(100 100 111 / 20%) 0px 4px 5px;
  margin: 28px;

}
.destination-shadow-box:hover {
    // transform: translateX(0.4rem) translateY(-1rem);
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }


.destination-shadow-box img{
  width: 100%;
}

.destination-padding-box{
  padding: 20px;
  
}


.destination-padding-box h3{
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align:center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  }

  .destination-padding-box p {
  color: #000;
  font-size: 16px;
  font-family: Poppins,sans-serif;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-bottom: 0.8rem;
  }


  .hover-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hover-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d0a133;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hover-to-right:hover,
  .hover-to-right:focus,
  .hover-to-right:active {
    color: white;
  }
  .hover-to-right:hover:before,
  .hover-to-right:focus:before,
  .hover-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  
  .testbg{
    background: #006a8f;
    width: 100%;
    position: relative;
    padding: 15px 20px 15px 40px!important;
    font-family: 'Raleway',sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;

  }





  

@media screen and (max-width: 768px) {
  .tour-destination {
    // gap: 2rem;
    display: grid;
    grid-template-columns: 100%;
  }
}



@media screen and (min-width: 280px) and (max-width: 1024px) {
  overflow:hidden;
  padding: 2rem 0 2rem 0;
  
  .overlay {
    background-color: rgba(0,0,0,0.6);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

}



`;
