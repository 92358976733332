import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import styled from "styled-components";
// import CountUp from 'react-countup';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import HeaderImage from "../assets/Visa/india.jpg";

import { Alert, Modal } from '@mui/material';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

 export default function Services3() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_o33bxy8', 'template_olni7wt', form.current, 'm6MT_Ax4mDmJD6_bB')
      .then((result) => {
  
      }, (error) => {
          console.log(error.text);
      }
      );
      e.target.reset()
  };

  return (
    <>
      
      <Navbar />

    <ServicesHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> India VISA REQUIREMENT'S </h1>
        </div>
      </div>
    </ServicesHeaderSection>


    <ServicesSection>
        <div className="main-container">
            
          <div className="services-item">
            <div>

                <p> <b>For Individual</b> </p>
             
              <p>
            <ul>
            <li>🔹 Passport Bio Page Scanned (Forward - Must be valid for 06 months) </li>
            <li>🔹 01 Photo Soft Copy (2*2 Size Photo White Background)</li>
            <li>🔹 Birth certificate</li> 
            <li>🔹 India entry/exit port name </li>
          
            </ul>
            </p>

            <p> <b>For Family</b> </p>

            <p>
            <ul>
            <li>🔹 Birth Certificate (Original & Translated)  </li>
            <li>🔹 Marriage certificate (Original & Translated)</li>
            </ul>
            </p>
              

            </div>
          {/* Right side */}
            <div>
                <div className="right-inqure-box">
            <h2> Inquire </h2>

            <a className="inqure-submit-btn hover-to-right" href="/inqure">Book Now</a>

    {/* <form ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                      <input required  type="text" name="user_name" placeholder="Name*" />
                  </div>

                  <div className="form-group">
                      <input required type="text" name="user_email" placeholder="Email*" />
                  </div>

                  <div className="form-group">
                    <textarea required type="text" rows="4" name="message" placeholder="Message" />
                  </div>

                  <div className="form-group">
                   


                    <Popup trigger={ <button type="submit" className="inqure-submit-btn hover-to-right"> Submit </button> } position="right center">
        <div className='thank-you-message'> Thank You!!!</div>
      </Popup>




                  </div>

    </form> */}

                </div>
            </div>
          {/* Right side END */}

          </div>

          </div>
      </ServicesSection>
      

      <Footer />
    </>
  );
}

const ServicesHeaderSection = styled.section`
overflow: hidden;
// padding: 14rem 0 8rem 0;
margin-bottom: 3rem;

.overlay {
  background: rgb(0 0 0 / 40%);
  padding: 16rem 0 10rem 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


h1{
  color:#fff;
  font-size: 46px;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 600;
  text-align: center;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  -webkit-letter-spacing: 4px;
  -moz-letter-spacing: 4px;
  -ms-letter-spacing: 4px;
  letter-spacing: 4px;
}

@media screen and (max-width: 425px){

  margin-bottom: 1rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 6rem 0 6rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  h1{
    font-size: 40px;
  }

}

`;


const ServicesSection = styled.section`
  overflow: hidden;
  padding: 4rem 0 4rem 0;

  .services-item {
    overflow: hidden;
    gap:4%;
    display: grid;
    grid-template-columns: 68% 28%;
  }

  .services-item h2{
    margin-bottom: 20px;
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    text-align: left;
  }

  .services-item p{
    position: relative;
    color: #000;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    margin-bottom: 30px;
    -webkit-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    line-height: 28px;
    text-align: justify;
}

.right-inqure-box{
  padding: 30px;
  overflow: hidden;
  border: rgb(100 100 111 / 20%) 1px solid;
  border-radius: 15px;
  text-align: center;
}

.right-inqure-box h2{
  margin-bottom: 20px;
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Raleway',sans-serif;
  font-weight: 500;
  text-align: center;
}

.form-group{
  padding: 10px 0;
  margin-bottom: 5px;
  overflow: hidden;
}

.form-group input,textarea{
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 20px 20px;
  font-size: 16px;
}

.form-group select{
  width: 100%;
  border: 1px solid #dfdfdf;
  padding: 20px 20px;
  font-size: 16px;
}


.inqure-submit-btn{
  cursor: pointer;
  background: #d0a133;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 15px 30px;
  border: 2px solid #fff;
  color: #fff;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius:0px;
  -webkit-transition: background-color 0.3s,border-color 0.3s;
  -moz-transition: background-color 0.3s,border-color 0.3s;
  -ms-transition: background-color 0.3s,border-color 0.3s;
  -o-transition: background-color 0.3s,border-color 0.3s;
  -webkit-transition: background-color 0.3s,border-color 0.3s;
  transition: background-color 0.3s,border-color 0.3s;
}

.hover-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hover-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #006a8f;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-to-right:hover,
.hover-to-right:focus,
.hover-to-right:active {
  color: white;
}
.hover-to-right:hover:before,
.hover-to-right:focus:before,
.hover-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

  .alert {
    padding: 20px;
    background-color: #f44336;
    color: white;
  }
  
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .closebtn:hover {
    color: black;
  }

  .thank-you-message{
    color: green;
    font-size: 16px;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 0 1rem 0;

    .services-item {
      gap:0%;
      display: grid;
      grid-template-columns: 100%;
    }

}



`;
