import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo-three.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";

import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [navbarState, setNavbarState] = useState(false);
  const ativelink = 'menuactivelink';
  const normallink = '';
  return (
    <>
      <section className="main-section-header">
        <div className="main-container">
          <Nav>
            <div className="brand">
              <div className="container">
              <NavLink to="/">
                  <img src={logo} className="logo" alt="Three Travel" />
              </NavLink>
              </div>
              <div className="toggle">
                {navbarState ? (
                  <VscChromeClose onClick={() => setNavbarState(false)} />
                ) : (
                  <GiHamburgerMenu className="togglesvg" onClick={() => setNavbarState(true)} />
                )}
              </div>
            </div>

            <ul>
              <li>
                <NavLink to="/" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
                  Home
                </NavLink>
                {/* <a href="/">Home</a> */}
              </li>
              <li>
              <NavLink to="/about-us" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
              About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/visa" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
                  Visa
                </NavLink>
              </li>
              <li>
              <NavLink to="/packages" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
                  Tour Packages
                </NavLink>
              </li>
              <li>
              <NavLink to="/gallery" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
                  Gallery
                </NavLink>
              </li>
              <li>
              <NavLink to="/contact" className={({ isActive })=>
                isActive ?  ativelink:normallink } >
                  Contact Us
                </NavLink>
              </li>
            </ul>

            <div className="class-button ">

              <NavLink to="/inqure"><button className="hover-to-right">BOOK NOW STAY LATER</button></NavLink>
            </div>
          </Nav>
          <ResponsiveNav state={navbarState}>
            <ul>
              <li>
                <a href="/" onClick={() => setNavbarState(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="/about-us" onClick={() => setNavbarState(false)}>
                  About Us
                </a>
              </li>
              <li>
                <a href="/visa" onClick={() => setNavbarState(false)}>
                Visa
                </a>
              </li>
              <li>
                <a href="/packages" onClick={() => setNavbarState(false)}>
                Tour Packages
                </a>
              </li>
              <li>
                <a href="/gallery" onClick={() => setNavbarState(false)}>
                Gallery
                </a>
              </li>
              <li>
                <a href="/contact" onClick={() => setNavbarState(false)}>
                Contact Us
                </a>
              </li>

              <li>
                <a href="/inqure" onClick={() => setNavbarState(false)}>
                Book Now Stay Later
                </a>
              </li>

            </ul>
          </ResponsiveNav>
        </div>
      </section>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    display: contents;
  }

  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-family: Poppins,sans-serif;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .toggle {
      display: none;
      
    }
  }

  ul {
    display: flex;
    gap: 1rem;
    list-style-type: none;
    li {
      a {
        font-family: Poppins,sans-serif;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #fff;
        padding: 14px;

        transition: 0.3s ease-in-out;
        text-transform: uppercase;
        &:hover {
          color: #d0a133;
        }
      }
      
    }
  }

  .menuactivelink{
    color: #d0a133;
    // border-bottom: 3px solid #006a8f;
  }

  button {
    padding: 12px 20px;
    cursor: pointer;
    font-family: Poppins,sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #003b54d1;
    color:#fff;
    border: 1px solid #d0a133;
    letter-spacing: 0.1rem;
    transition: 0.3s ease-in-out;

    &:hover {
    
    color:#fff;
    border: 1px solid #d0a133;
    }
  }

  .hover-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .hover-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #d0a133;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hover-to-right:hover,
  .hover-to-right:focus,
  .hover-to-right:active {
    color: white;
  }
  .hover-to-right:hover:before,
  .hover-to-right:focus:before,
  .hover-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }


  @media screen and (min-width: 280px) and (max-width: 1080px) {

    .brand {
      display: flex;
     
      justify-content: space-between;
      align-items: center;
      
      .toggle {
        display: block;
        z-index: 99999;
        font-size: 30px;
        
        
      }

      .togglesvg{
        color:#fff;
      }

    }
    ul {
      display: none;
    }

    button {
      display: none;
    }
    .class-button {
      display: none;
    }
    .logo{
      width: 100%;
    }
  }


`;

const ResponsiveNav = styled.div`
  display: flex;
  position: fixed;
  left: 0px;
  z-index: 9999;
  top: ${({ state }) => (state ? "0px" : "-400px")};
  background-color: white;
  width: 100%;
  overflow: hidden;
  align-items: center;
  transition: 0.3s ease-in-out;
  padding:10px 0;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      text-align: center;

      a {
        font-family: 'Raleway',sans-serif;
        text-decoration: none;
        color: #0077b6;
        font-size: 18px;
        transition: 0.1s ease-in-out;
       
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #023e8a;
          font-weight: 600;
          font-family: 'Raleway',sans-serif;
        }
      }
    }
  }



`;
