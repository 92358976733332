import React from "react";
import styled from "styled-components";
// import CountUp from 'react-countup';
import ScrollToTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HeaderImage from "../assets/About-us/about-us.jpg";
import image from "../assets/three-travels-about-home.jpg";


export default function Aboutuspage() {
  return (
    <>
      <ScrollToTop />
      <Navbar />

    <AboutHeaderSection style={{ backgroundImage:`url(${HeaderImage})`,backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}>
      <div className="overlay">
        <div className="main-container">
              <h1> About Us </h1>
        </div>
      </div>
    </AboutHeaderSection>

  
      <Aboutsection>
        <div className="main-container">
          <div className="about-left">
            <img className="responsive" src={image} alt="" />
          </div>

          <div className="about-right">
            <div className="about-box-center">
              <p className="about-threetravel"> THREE TRAVELS </p>
              <h2> Three Travels & Tours (PVT) Ltd </h2>
              <p className="about-box-center-paragraph">
              At Three Travels & Tours Pvt Ltd, we specialize in providing comprehensive travel and tour 
              solutions tailored to meet the needs of both individual travelers and families. Our core services 
              include the sale and distribution of air tickets, overseas hotel bookings, worldwide leisure travel, 
              family tours, and expert visa processing and consultation. We are committed to offering unique 
              opportunities for our clients to explore diverse cultures and experience the beauty of nature around 
              the world. With our extensive knowledge and dedication to customer satisfaction, we ensure that every 
              journey is seamless and memorable. Let us help you create unforgettable travel experiences.
              </p>
            </div>
          </div>
        </div>
      </Aboutsection>


      {/* <Aboutsection>
        <div className="main-container">
          <div className="about-right-2">
            <div className="about-box-center">
              <p className="about-threetravel"> THREE TRAVEL </p>
              <h2> Our Achievements </h2>
              <p className="about-box-center-paragraph">
                {" "}
                Lorem ipsum dolor sit amet consectetur adipiscing elit sed do
                eiu smod tempor incididunt ut labore dolore magna aliqua.Quis
                ipsum suspen disse ultrices gravida Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
            </div>
          </div>

          <div className="about-left">
            <img className="responsive" src={image} alt="" />
          </div>


        </div>
      </Aboutsection> */}


      <Footer />
    </>
  );
}

const AboutHeaderSection = styled.section`
  overflow: hidden;
  // padding: 14rem 0 8rem 0;
  margin-bottom: 3rem;

  .overlay {
    background: rgb(0 0 0 / 40%);
    padding: 16rem 0 10rem 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }


  h1{
    color:#fff;
    font-size: 46px;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    text-align: center;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    letter-spacing: 4px;
  }

  @media screen and (max-width: 425px){
    margin-bottom: 1rem;
    .overlay {
      background: rgb(0 0 0 / 40%);
      padding: 6rem 0 6rem 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    h1{
      font-size: 40px;
    }

  }

`;



const Aboutsection = styled.section`
  overflow: hidden;
  padding: 2rem 0 4rem 0;

  .about-us {
    width: 100%;

    overflow: hidden;
  }




  .about-box-center-1 {
    margin-top: 2rem;
  }


  .about-left {
    width: 40%;
    float: left;
  }

  .about-left img {
    position: relative;
    display: block;
    overflow: hidden;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.2);
  }

  .responsive {
    width: 100%;
    height: auto;
  }

  .about-right {
    width: 50%;
    float: left;
    padding-left: 10%;
  }

  .about-right-2 {
    width: 50%;
    float: left;
    padding-right: 10%;
  }

  .about-box-center {
    margin-top: 2rem;
  }

  .about-threetravel {
    font-family: Poppins,sans-serif;
    // font-size: 11px;
    font-size: 36px;
    -webkit-letter-spacing: 4px;
    -moz-letter-spacing: 4px;
    -ms-letter-spacing: 4px;
    letter-spacing: 4px;
    line-height: 18px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .about-box-center h2 {
    margin-bottom: 10px;
    // font-size: 36px;
    font-size:16px;
    color: #000;
    text-transform: uppercase;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    text-align: left;
  }

  .about-box-center-paragraph {
    position: relative;
    color: #000;
    font-size: 16px;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    margin: 0px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    line-height: 28px;
    text-align: justify;
  }

  @media screen and (min-width: 280px) and (max-width: 1024px) {
    padding: 1rem 0 1rem 0;

    .about-left {
      width: 100%;
      float: left;
    }

    .about-right {
      width: 100%;
      float: left;
      padding: 0px;
    }
    
    .about-threetravel{
      text-align: center;
    }
    
    .about-box-center h2 {
      text-align: center;
    }

  }

  @media screen and (max-width: 375px) {
    
    .about-box-center h2 {
      text-align: center;
      word-break: break-all;
    }
  
  }  

`;
